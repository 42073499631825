export const intersectionObserver = (selector, classList) => {
  const callback = function (entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add(...classList.split(' '))
        observer.unobserve(entry.target)
      }
    })
  }

  const observer = new IntersectionObserver(callback, { threshold: 0.3 })

  const targets = document.querySelectorAll(`.${selector}`)

  targets.forEach(function (target) {
    observer.observe(target)
  })
}
