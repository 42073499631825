<template>
  <Layout />
</template>

<script>
import Layout from '@/layouts/Default'

export default {
  components: {
    Layout,
  },
}
</script>
