<template>
  <div class="flex flex-col">
    <div class="text-6xl -mb-8 font-gothic font-light text-theme6 text-center">|</div>
    <div class="bg-theme6 bg-opacity-20 h-96 md:h-128 shadow-xl">
      <div class="mt-12 md:mt-16 opacity-100 text-center font-barlow text-theme6 text-3xl md:text-4xl lg:4.5xl">
        STEP {{ step }}
      </div>
      <div class="font-barlow font-bold text-3xl md:text-4xl lg:4.5xl text-theme7 text-center mt-8 md:mt-24">
        {{ title }}
      </div>
      <div class="border border-theme6 mx-10 my-6 md:my-8"></div>
      <div class="px-8 text-center text-md lg:text-lg font-light font-gothic text-theme8">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: String,
    title: String,
    description: String,
  },
}
</script>
