<template>
  <div class="grid grid-cols-12 p-12 md:px-main bg-theme1 font-cute">
    <div class="col-span-3 pb-8 md:pb-16 leading-4 md:leading-8 text-xl md:text-3xl lg:text-5xl z-50 text-theme9">
      <div class="block hover:text-theme3 cursor-pointer leading-4 md:leading-8">The<br />Breeze</div>
    </div>
    <div
      class="
        col-span-5
        pb-8
        md:pb-16
        flex flex-col
        gap-2
        md:gap-6
        text-theme10 text-xs
        md:text-xl
        lg:text-2xl
        font-barlow
      "
    >
      <router-link to="/about" class="hover:text-theme3">about-me</router-link>
      <router-link to="/work" class="hover:text-theme3">how-to-work</router-link>
      <router-link to="/contact" class="hover:text-theme3">contact</router-link>
    </div>
    <div class="col-span-4 flex items-end justify-end pt-8 text-theme10 text-xs md:text-lg lg:text-lg">
      @ BREEZE company
    </div>
  </div>
</template>
