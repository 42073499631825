export default [
  {
    step: '01',
    title: 'Interview',
    description: '프로그램을 통해 무엇을 얻고 싶은지, 어떤 기능이 들어갔으면 좋은지에 대한 내용을 파악하는 단계입니다.',
  },
  {
    step: '02',
    title: 'Design',
    description: '프로그램이 어떤 방식으로 구현될지 미리 그려보는 단계입니다. 전체적인 그림을 체크하는 단계입니다.',
  },
  {
    step: '03',
    title: 'Develop',
    description: '결정된 디자인으로 개발이 시작됩니다. 개발이 얼마나 진행되고 있는지 알 수 있도록 URL을 제공해드려요.',
  },
  {
    step: '04',
    title: 'Deploy',
    description:
      '프로그램을 사용해보고 이상이 없을 경우, 사전에 합의된 내용에 따라 코드를 제공하거나 서버에 배포합니다.',
  },
]
