<template>
  <div
    class="
      move-right
      -left-6
      absolute
      flex
      z-10
      w-full
      h-full
      items-center
      text-center
      justify-center
      text-5xl
      md:text-7.5xl
      text-theme3
      font-bold font-barlow
    "
  >
    {{ title }}
  </div>
  <img
    class="fade-in opacity-0 absolute z-0 object-contain w-full h-full"
    src="/images/wave-line.png"
    alt="wave-line"
  />
</template>

<script>
import { intersectionObserver } from '@/utils/intersectionObserver'
import { onMounted } from '@vue/runtime-core'

export default {
  props: {
    title: String,
  },

  setup() {
    onMounted(() => {
      const moveRight = 'transition ease-out duration-1500 transform translate-x-6'
      intersectionObserver('move-right', moveRight)
      const fadeIn = 'transition ease-in duration-1500 opacity-100'
      intersectionObserver('fade-in', fadeIn)
    })
  },
}
</script>
