<template>
  <div
    id="navbar"
    class="
      transtion
      duration-300
      ease-out
      h-32
      md:h-40
      fixed
      z-40
      w-full
      px-4
      md:px-12
      lg:px-24
      flex
      items-center
      justify-between
    "
  >
    <router-link
      to="/"
      id="logo"
      class="font-cute leading-3 md:leading-6 text-xl md:text-4xl z-50 text-theme2 hover:text-theme3"
    >
      The<br />
      Breeze
    </router-link>
    <div class="font-barlow hidden md:flex md:space-x-8 md:text-xl lg:text-1.5xl text-theme5">
      <div class="hover:text-theme2"><router-link cas to="/about">about-me</router-link></div>
      <div class="hover:text-theme2"><router-link cas to="/work">how-to-work</router-link></div>
      <div class="hover:text-theme2"><router-link cas to="/contact">contact</router-link></div>
    </div>
    <div id="mobile-menu" class="z-10 md:hidden" @click="mobileMenuToggle()">
      <svg
        :class="[state.isOpen ? 'transform rotate-45 active' : '']"
        class="w-8 h-8 mobile-menu cursor-pointer transtion duration-300"
        viewBox="0 0 100 100"
        width="80"
      >
        <path
          class="line top"
          d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
        />
        <path class="line" d="m 30,50 h 40" />
        <path
          class="line bottom"
          d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
        />
      </svg>
    </div>
    <!-- mobile menu toggle animation -->
    <transition
      enter-active-class="transition ease-in duration-300"
      enter-from-class="transform opacity-0 -translate-y-6"
      enter-to-class="transform opacity-100"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform opacity-100"
      leave-to-class="transform opacity-0 -translate-y-6"
    >
      <div
        v-if="state.isOpen"
        id="mobile-menu-open"
        class="absolute right-0 top-0 pt-12 w-full text-lg md:hidden text-theme2 bg-theme4"
        @click="state.isOpen = !state.isOpen"
      >
        <router-link to="/about" class="block py-2 text-center">about-me</router-link>
        <router-link to="/work" class="block py-2 text-center">how-to-work</router-link>
        <router-link to="/contact" class="block py-2 text-center">contact</router-link>
      </div>
    </transition>
  </div>
  <!-- trigger nabver intersectionObserver -->
  <div id="navbar-background" class="absolute z-0 w-full"></div>
  <!-- mobile menu close when other field click -->
  <div v-if="state.isOpen" class="absolute h-full w-full z-30" @click="state.isOpen = false"></div>
</template>

<script>
import { onMounted, reactive } from '@vue/runtime-core'

export default {
  name: 'Navbar',

  setup() {
    const state = reactive({
      isOpen: false,
    })

    const mobileMenuToggle = () => {
      if (window.scrollY < 1) {
        window.scrollTo(0, 1)
      }
      state.isOpen = !state.isOpen
    }

    onMounted(() => {
      const navbar = document.getElementById('navbar')
      const navbarEffect = 'bg-theme4 h-10 md:h-20'
      const targetBackground = document.getElementById('navbar-background')
      // const logo = document.getElementById('logo')
      // const logoEffect = 'leading-3 md:leading-6 text-xl md:text-4xl'

      const callback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // when scroll top
            navbar.classList.add('h-32', 'md:h-40')
            navbar.classList.remove(...navbarEffect.split(' '))
            // logo.classList.add('leading-8', 'md:leading-12', 'text-5xl', 'md:text-7.5xl')
            // logo.classList.remove(...logoEffect.split(' '))
          } else {
            // when scroll down
            // navbar.className = [...navbarEffect.split(' '), ...navbar.classList].join(' ')
            // logo.className = [...logoEffect.split(' '), ...logo.classList].join(' ')
            navbar.classList.remove('h-32', 'md:h-40')
            navbar.classList.add(...navbarEffect.split(' '))
            // logo.classList.remove('leading-8', 'md:leading-12', 'text-5xl', 'md:text-7.5xl')
            // logo.classList.add(...logoEffect.split(' '))
          }
        })
      }
      const observer = new IntersectionObserver(callback)
      observer.observe(targetBackground)
    })

    return { state, mobileMenuToggle }
  },
}
</script>

<style scoped>
.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #457663e5;
  stroke-width: 3;
  stroke-linecap: round;
}
.mobile-menu .top {
  stroke-dasharray: 40 139;
}
.mobile-menu .bottom {
  stroke-dasharray: 40 180;
}
.mobile-menu.active .top {
  stroke-dashoffset: -98px;
}
.mobile-menu.active .bottom {
  stroke-dashoffset: -138px;
}
</style>
