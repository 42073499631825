<template>
  <section class="grid grid-cols-6 md:grid-cols-12 gap-8">
    <div class="relative col-span-5 md:col-span-5 bg-theme6">
      <div class="opacity-50">
        <video class="w-full" src="../assets/videos/breeze.mp4" playsinline autoplay muted loop></video>
      </div>
      <div
        class="
          move-top
          absolute
          left-0
          xl:left-1
          z-20
          bottom-14
          md:bottom-32
          lg:bottom-36
          xl:bottom-76
          px-2
          w-69
          xl:w-80
          h-64
          uppercase
          font-barlow
          text-2xl
          md:text-3xl
          xl:text-4.5xl
          text-theme4
          transform
          -rotate-90
        "
      >
        Hello, It's me!
      </div>
      <div class="absolute left-0 xl:left-8 bottom-0 h-16 md:h-32 lg:h-32 xl:h-72 border-r w-7 border-theme4"></div>
      <img
        id="mainPhoto"
        class="move-down absolute z-20 shadow-lg bottom-14 md:bottom-16 ml-12 md:ml-16 xl:ml-32 2xl:ml-52"
        src="/images/main-photo.png"
        alt="main-photo"
      />
    </div>
    <div class="md:col-span-2"></div>
    <div class="move-left pl-10 pr-4 md:px-0 col-span-6 md:col-span-5 flex items-center">
      <div>
        <div class="font-barlow font-bold text-theme3 text-8.5xl -ml-1.5 mt-12">ME?</div>
        <div class="font-gothic font-thin text-theme3 py-2 leading-loose">
          컴퓨터로 이것저것 만드는거 좋아해요.<br />그래서 개발도 하고 디자인도 해요.<br />그리고 따뜻한거 좋아해요.<br />
          색도, 음악도.
        </div>
        <button
          class="
            flex
            items-center
            font-gothic
            text-md
            md:text-xl
            text-theme1
            hover:bg-theme1
            border border-theme1
            hover:text-theme4
            py-3
            px-6
          "
          @click="$router.push('/about')"
        >
          About me &nbsp;&nbsp;ᐳ
        </button>
      </div>
    </div>
  </section>

  <section class="relative mt-24 h-52 md:h-80 lg:h-96">
    <HomeDivider title="Recent Work" />
  </section>

  <section class="relative mt-24">
    <div class="hidden absolute w-full md:grid grid-cols-12 gap-8">
      <div class="col-span-7"></div>
      <div class="col-span-5 h-128 bg-theme5 my-80">
        <div class="absolute right-0 border-l h-128 border-theme4 w-12"></div>
        <div
          class="absolute px-2 mt-48 -right-20 text-2xl font-barlow uppercase bg-theme5 text-theme4 transform rotate-90"
        >
          This is my portfolio!
        </div>
      </div>
    </div>
    <div class="w-full px-8 md:px-0">
      <div class="scale-up transform scale-75">
        <div class="text-center text-2xl md:text-3xl text-theme6 font-barlow font-bold">2021</div>
        <div class="flex justify-center">
          <div class="-mb-7 z-20 border border-theme6 h-12 w-0"></div>
        </div>
        <div class="flex justify-center">
          <img class="fade-in w-152 z-10" src="/images/project01.png" alt="project01" />
        </div>
        <div class="text-center font-gothic text-theme6 font-bold text-3xl">건강정보 문서화</div>
        <div class="flex items-center justify-center font-barlow text-theme5 hover:text-theme6 text-xl">
          <a class="flex items-center" href="https://icfcoreset.net" target="_blank">
            https://icfcoreset.net
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="external-link-alt"
              class="mt-0.5 ml-1 h-4 w-4 svg-inline--fa fa-external-link-alt fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"
              ></path>
            </svg>
          </a>
        </div>
      </div>
      <div class="scale-up transform scale-75">
        <div class="pt-8 md:pt-24 text-center text-2xl md:text-3xl text-theme6 font-barlow font-bold">2021</div>
        <div class="flex justify-center">
          <div class="-mb-7 z-20 border border-theme6 h-12 w-0"></div>
        </div>
        <div class="flex justify-center">
          <img class="w-152 z-10" src="/images/project02.png" alt="project02" />
        </div>
        <div class="text-center font-gothic text-theme6 font-bold text-3xl">쇼핑몰 상품관리</div>
        <div class="flex items-center justify-center font-barlow text-theme5 hover:text-theme6 text-xl">
          <a class="flex items-center" href="https://youtu.be/27pVUCWCSjo" target="_blank">
            https://youtu.be/27pVUCWCSjo
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="external-link-alt"
              class="mt-0.5 ml-1 h-4 w-4 svg-inline--fa fa-external-link-alt fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"
              ></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </section>

  <section class="relative mt-24 h-52 md:h-80 lg:h-96">
    <HomeDivider title="Working step" />
  </section>

  <section class="relative mt-24">
    <div class="px-4 md:px-main grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
      <div v-for="step in steps" :key="`step ${step.step}`">
        <StepCard
          class="scale-up transform scale-75"
          :step="step.step"
          :title="step.title"
          :description="step.description"
        />
      </div>
    </div>
    <div class="flex justify-center mt-12 lg:mt-32">
      <button
        class="
          flex
          items-center
          font-gothic
          text-md
          md:text-xl
          text-theme1
          hover:bg-theme1
          border border-theme1
          hover:text-theme4
          py-3
          px-6
        "
        @click="$router.push('/work')"
      >
        How to work &nbsp;&nbsp;ᐳ
      </button>
    </div>
  </section>

  <section class="relative my-24 h-52 md:h-80 lg:h-96">
    <HomeDivider title="Need More?" />
  </section>

  <section>
    <div class="flex justify-center mb-12 lg:mb-32">
      <button
        class="
          flex
          items-center
          font-gothic
          text-md
          md:text-xl
          text-theme1
          hover:bg-theme1
          border border-theme1
          hover:text-theme4
          py-3
          px-6
        "
        @click="$router.push('/contact')"
      >
        Contact Me! &nbsp;&nbsp;ᐳ
      </button>
    </div>
  </section>
</template>

<script>
import StepCard from '@/components/StepCard'
import homeSteps from '@/utils/homeSteps'
import { reactive, ref } from '@vue/reactivity'
import HomeDivider from '@/components/HomeDivider'
import { onMounted } from '@vue/runtime-core'
import { intersectionObserver } from '@/utils/intersectionObserver'

export default {
  name: 'Home',

  components: {
    StepCard,
    HomeDivider,
  },

  setup() {
    const steps = reactive(homeSteps)
    const mainPhoto = ref()

    onMounted(() => {
      const moveTop = 'transition ease-out duration-1000 transform -translate-y-6'
      intersectionObserver('move-top', moveTop)
      const moveDown = 'transition ease-out duration-1000 transform translate-y-6'
      intersectionObserver('move-down', moveDown)
      const moveLeft = 'transition ease-out duration-1000 transform -translate-x-6'
      intersectionObserver('move-left', moveLeft)
      const scaleUp = 'transition ease-out duration-1000 transform scale-100'
      intersectionObserver('scale-up', scaleUp)
    })

    return { steps, mainPhoto }
  },
}
</script>
